<template>
  <div class="relative bg-gray-50">
    <div class="fixed  w-full zindex">
      <Header class="zindex" />
      <div class="py-2 w-full">
        <Navbar />
        <Breadcrumb class="pl-10 mt-3" />
      </div>
    </div>
    <div
      class="w-full max-w-full pt-10 flex-grow bg-gray-50 relative h-full max-h-full  overflow-x-hidden "
    >
      <transition name="slide-fade" mode="out-in" appear>
        <router-view class="relative" />
      </transition>
      <Footer class="pt-10 mt-10" />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/UI/Navbar/Navbar";
import Header from "@/components/UI/Header";
import Footer from "@/components/UI/Footer";
import Breadcrumb from "@/components/UI/Breadcrumb";
export default {
  components: {
    Navbar,
    Header,
    Breadcrumb,
    Footer,
  },
  created() {},
};
</script>

<style>
.zindex {
  z-index: 999;
  position: relative;
}
</style>