<template>
  <footer class="w-full flex flex-col justify-center items-center bg-blue-light">
      <div class="w-11/12 p-8 mb-10 text-left bg-white text-gray-600 shadow-md">
          <h2 class="mb-5 text-xl text-blue-dark"><b class="text-md">Superintendencia Financiera de Colombia</b></h2>
          <p class="mb-2 text-blue"><b>Atención presencial</b></p>
          <div class="text-gray-500 text-sm">
            <p><b>Punto Ciudadano:</b></p>
            <p>Calle 7 No. 4-49</p>
            <p>Bogotá, D.C.</p>
            <p><b>Horario:</b></p>
            <p>Jornada continua, días hábiles.</p>
            <p>8:15 a.m. - 4:15 p.m.</p>
          </div>
          <p class="mt-4 mb-2 text-blue"><b>Atención telefónica</b></p>
          <div class="text-gray-500 text-sm">
            <p>+57 601 307 8042</p>
            <p><b>Horario:</b></p>
            <p>Jornada continua, días hábiles.</p>
            <p>Lunes a viernes, 7:30 a.m. - 9:00 p.m.</p>
            <p>Sábados, 8:00 a.m. - 4:00 p.m.</p>
          </div>
          <p class="mt-4 mb-2 text-blue"><b>Canales virtuales</b></p>
          <div class="text-gray-500 text-sm">
            <p><b>WhatsApp: </b>+57 317 639 8781 </p>
            <p><b>Chat virtual: </b><a href="https://www.superfinanciera.gov.co" target="_blank" rel="noopener">www.superfinanciera.gov.co</a></p>
          </div>
      </div>
      <div class="h-14 w-full py-2 flex justify-center items-center bg-blue">
          <div class="h-full w-11/12 flex items-center">
            <LogoCOGOV class="h-4/5 w-auto" />
          </div>
      </div>
  </footer>
</template>

<script>
import LogoCOGOV from '@/components/Brand/LogoCOGOV'
export default {
    components: {
        LogoCOGOV
    }
}
</script>